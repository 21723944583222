<template>
  <div>
    <el-form class="manageForm" :model="manageForm" :inline="true">
      <el-form-item label="企业名称：" prop="EnterpriseFullName">
        <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称" @keyup.enter.native="search()"></el-input>
      </el-form-item>
      <el-form-item label-width="10px">
        <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
        <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
      <el-table-column type="index" align="center" label="序号" width="50" fixed show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" fixed show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="CheckApplyTime" label="申请人工审核时间" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="wayReviewCount" label="总计运单数量" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseAddress" label="操作" width="130">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="manualClick(scope.row,pagination.page,'alreadyAudit')">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { GetReviewWayList } from "@/api/manualAudit/index";
export default {
  data() {
    return {
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: ""
      },
      // 表格loading
      loading: false,
      // 表格数据
      tableData: [],
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
    }
  },
  methods: {
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/auditInfo/manualAudit/index') {
        // 重置路由
        this.$router.push({
          path: "/auditInfo/manualAudit/index"
        });
      }
    },
    // 人工审核
    manualClick(item, pageIndex,type) {
      this.$router.push({
        path: '/auditInfo/manualAudit/auditInfo',
        query: {
          UserID: item.UserID,
          EnterpriseFullName: item.EnterpriseFullName,
          pageIndex,
          type
        },
      });
    },
    // 搜索
    search() {
      this.pagination.page = 1
      // 获取人工审核列表
      this.GetReviewWayList()
    },
    // 清空搜索
    resetForm() {
      this.pagination.page = 1
      this.manageForm = {
        EnterpriseFullName: ''
      }
      // 获取人工审核列表
      this.GetReviewWayList()
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e
      // 获取人工审核列表
      this.GetReviewWayList()
    },
    // 获取人工审核列表
    GetReviewWayList() {
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        ManualStatus: 1, //0未审核 1已审核
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize
      }
      GetReviewWayList({ Json: JSON.stringify(data) }).then(res => {
        this.tableData = res.WayReviewList
        this.pagination.total = Number(res.totalRowCount)
      })
    }
  },
  created() {
    if (this.$route.query.pageIndex) {
      this.pagination.page = Number(this.$route.query.pageIndex)
    }
    // 获取人工审核列表
    this.GetReviewWayList()
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  }
}
</script>

<style lang="scss" scoped>
</style>